import '../assets/style/main.scss';
import '../assets/style/home.scss';

import { FaArrowRight } from 'react-icons/fa';

import React from 'react';

function Home() {
    // return <div className="homeContainer">
    //         <a href="/about" className="wrapper info">
    //             <div className="content">
    //                 <h1>Sint-Gertrudiskerk Kuringen</h1>
    //                 <p>Welkom bij uw bezoek aan de Sint-Gertrudiskerk van Kuringen.</p>
    //                 <br/>
    //                 <p>Klik voor meer info over onze kerk.</p>
    //             </div>
    //         </a>
    //         <a href="/rondleiding" className="wrapper rondleiding">
    //             <div className="content">
    //                 <h1>Online rondleiding</h1>
    //                 <p>Bekijk de rondleiding thuis, of volg hem in de kerk.</p>
    //                 <br/>
    //                 <p>Klik om de rondleiding te starten.</p>
    //             </div>
    //         </a>
    // </div>
    return <div className='homeContainerv2'>
        <h1 className='header'>Sint-Gertrudiskerk</h1>
        <div>
            <h1>Welkom in de kerk van Kuringen.</h1>
            <section className='actions'>
                <a href="/rondleiding">
                    Start rondleiding <FaArrowRight/>
                </a>
                <a href="/noveen" className='inverted'>
                    Bekijk de Noveen <FaArrowRight/>
                </a>
            </section>
        </div>
    </div>
}

export default Home;
