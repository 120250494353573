import "../assets/style/main.scss";
import "../assets/style/rondleiding.scss";

import Banner from "../components/Banner.js";

import React from "react";

function Rondleiding() {
  return (
    <div>
      <Banner />
      <a href="/" className="breadcrumb">
        Ga terug naar startscherm
      </a>
      <div className="rondleidingContainer">
        <div className="rondleindingInfoContainer">
          <h1>Online rondleiding</h1>
          <br />
          <p>
            Hieronder vindt u een lijst met alle bezienswaardigheden binnen onze kerk.
          </p>
          <br />
          {/* <p>
            Indien u zich in de kerk bevindt is het ook mogelijk om via de QR-Code naar de
            info pagina te gaan. U kan vanaf dat punt dan verder of terug gaan in de
            rondleiding.
          </p> */}
          {/* <br />
          <p>Bezienswaardigheden van de kerk:</p> */}
          {/* <p className="muted">Klik op een Item voor meer info.</p> */}
        </div>
        <div className='rondleiding-items'>
          <div className="rondleiding-item">
            <p>1.</p>
            <a href="/rondleiding/1">Heiligenbeeld Sint-Gertrudis</a>
          </div>
          <div className="rondleiding-item">
            <p>2.</p>
            <a href="/rondleiding/2">Paaskandelaar</a>
          </div>
          <div className="rondleiding-item">
            <p>3.</p>
            <a href="/rondleiding/3">Doopvont</a>
          </div>
          <div className="rondleiding-item">
            <p>4.</p>
            <a href="/rondleiding/4">Doopkapels</a>
          </div>
          <div className="rondleiding-item">
            <p>5.</p>
            <a href="/rondleiding/5">Processiekruis</a>
          </div>
          <div className="rondleiding-item">
            <p>6.</p>
            <a href="/rondleiding/6">
              Plafondschildering met wapenschild van abdis Augustina van Hamme
            </a>          
          </div>
          <div className="rondleiding-item">
            <p>7.</p>
            <a href="/rondleiding/7">Triomfkruis</a>
          </div>
          <div className="rondleiding-item">
            <p>8.</p>
            <a href="/rondleiding/8">Godslamp</a>
          </div>
          <div className="rondleiding-item">
            <p>9.</p>
            <a href="/rondleiding/9">Hoofdaltaar</a>
          </div>
          <div className="rondleiding-item">
            <p>10.</p>
            <a href="/rondleiding/10">Credenstafel</a>
          </div>
          <div className="rondleiding-item">
            <p>11.</p>
            <a href="/rondleiding/11">Onze-Lieve-Vrouwaltaar</a>
          </div>
          <div className="rondleiding-item">
            <p>12.</p>
            <a href="/rondleiding/12">Klok</a>
          </div>
          <div className="rondleiding-item">
            <p>13.</p>
            <a href="/rondleiding/13">Biechtstoelen</a>
          </div>
          <div className="rondleiding-item">
            <p>14.</p>
            <a href="/rondleiding/14">Kruisweg</a>
          </div>
          <div className="rondleiding-item">
            <p>15.</p>
            <a href="/rondleiding/15">Heiligenbeeld Sint-Elooi</a>
          </div>
          <div className="rondleiding-item">
            <p>16.</p>
            <a href="/rondleiding/16">
              Schilderij Het mystieke huwelijk van de Heilige Catharina van Alexandrie
            </a>  
          </div>
          <div className="rondleiding-item">
            <p>17.</p>
            <a href="/rondleiding/17">Heiligenbeeld Jacobus de Mindere</a>
          </div>
          <div className="rondleiding-item">
            <p>18.</p>
            <a href="/rondleiding/18">Beeld Johannes de Doper</a>
          </div>
          <div className="rondleiding-item">
            <p>19.</p>
            <a href="/rondleiding/19">Schilderij Aanbidding door de Heilige Bernardus</a>
          </div>
          <div className="rondleiding-item">
            <p>20.</p>
            <a href="/rondleiding/20">
              Schilderij Kroning van Maria door de Heilige Drievuligheid
            </a>
          </div>
          <div className="rondleiding-item">
            <p>21.</p>
            <a href="/rondleiding/21">Beeld Christus op de koude steen</a>
          </div>
          <div className="rondleiding-item">
            <p>22.</p>
            <a href="/rondleiding/22">Preekstoel</a>
          </div>
          <div className="rondleiding-item">
            <p>23.</p>
            <a href="/rondleiding/23">De glasramen</a>
          </div>
          <div className="rondleiding-item">
            <p>24.</p>
            <a href="/rondleiding/24">Orgel</a>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
}

export default Rondleiding;
