import "../assets/style/main.scss";
import "../assets/style/rondleiding.scss";

import Banner from "../components/Banner.js";

import glasraam_1 from "../assets/images/rondleiding/23a.jpg";
import glasraam_2 from "../assets/images/rondleiding/23b.jpg";
import glasraam_3 from "../assets/images/rondleiding/23c.jpg";
import glasraam_4 from "../assets/images/rondleiding/23d.jpg";

function Glasramen() {
  const currentItem = 23;
  const nextItemLink = "/rondleiding/24";
  const previousItemLink = "/rondleiding/22";

  return (
    <div>
      <Banner />
      <a href="/rondleiding" className="breadcrumb">
        Ga terug naar de lijst
      </a>
      <div className="glass-container" id={currentItem}>
        <div className="item-content-container">
          <div className="item-navigation-control">
            <a href={previousItemLink}>Vorige</a>
            23
            <a href={nextItemLink}>Volgende</a>
          </div>{" "}
          <p className="muted">Nummer 23: De Glasramen (bevat 4 glasramen)</p>
          <div>
            <h1>De Glasramen</h1>
          </div>
          <br />
          <p className="item-content">
            Over de herkomst van de oude glasramen, die nu gerestaureerd zijn door
            Glasmalerei Peters, is niet veel gekend. In het archief van Monumenten en
            Landschappen in Sint-Truide bevindt zich een ingekleurde tekening, getekend
            Germain Jamine en gedateerd 1895 met de tekst 'Englise de Curange, premier
            project'. In het bulletin van de Koninklijke Commissie, staat te lezen dat in
            1896 een project voor nieuwe glasramen werd goedgekeurd, ontworpen door
            Dobbelaere.
          </p>
          <br />
          <div className="GlassContainer">
            <div className="GlassContainerItem">
              <div className="left">
                <p className="muted">Glasraam 1</p>
                <br />
                <div className="item-image-container">
                  <img src={glasraam_1} alt="Glasraam"></img>
                </div>
              </div>{" "}
              <div className="right">
                <br />
                <div>
                  <h1>Het leven van Sint-Gertrudis</h1>
                </div>

                <br />
                <p className="item-content">
                  Centraal in het middelste raam verwerkte Knaepen een beeltenis van
                  Sint-Gertrudis: haar cape, mantel en de abdisstaf waarlangs muizen
                  omhoog kruipen, zijn duidelijk te onderscheiden. Meestal werkt de
                  kunstenaar enkel en alleen met symboliek, omdat dit uitnodigd om naar de
                  diepere betekenis te kijken.
                  <br />
                  <br />
                  In dit glasraam staat Sint-Gertrudis in het midden, zoals zij ooit
                  temidden de mensen geleefd heeft. Van bovenaf schijnt een goudgele gloed
                  op haar neer, die symbool staat voor het goddelijke licht, het mystieke.
                  Hetzelfde geel is terug te vinden in het hart van Sint-Gertrudi. Het
                  blauw in het glasraam is een symbool voor het levende water dat Christus
                  is. <br /> Rechtsonderaan zien we West-Europa, linksonder
                  Latijns-Amerika met in het midden de duidelijke kloof, recht omhoog.
                  Tussen beide werelddelen is ook een brug zichtbaar. Deze afbeelding is
                  een hedendaags symbool voor de armenzorg, die Sint-Gertrudis tijdens
                  haar leven uitoefende met de stichting van een armenhuis in de buurt van
                  de abdij waar ze abdis was. De gouden muntstukjes en de harten staan ook
                  symbool voor deze zorg voor minderbedeelden.
                </p>
              </div>
            </div>
            <div className="GlassContainerItem">
              <div className="left">
                <br />
                <p className="muted">Glasraam 2</p>
                <br />
                <div className="item-image-container">
                  <img src={glasraam_2} alt="Glasraam"></img>
                </div>{" "}
              </div>
              <br />
              <div className="right">
                <div>
                  <h1>Het register Broederschap van Sint-Gertrudis</h1>
                </div>
                <br />

                <p className="item-content">
                  De kerkfabriek van de Sint-Gertrudisparochie is in het bezit van een
                  register van de Sint-Gertrudisbroederschap, waarin sinds 1418 namen
                  terug te vinden zijn van verscheidene prins-bisschoppen en hun
                  getrouwen, vooraanstaanden uit Hasselt en omgeving, musici, valkeniers,
                  abdissen en kloosterzusters van Herkenrode, kardinaal Gerard van
                  Groesbeek, geboren te Kuringen en van gewone burgers van Kuringen.
                  <br />
                  <br />
                  Onderaan het glasraam zie jet het register opengeslagen liggen. De
                  goudgele parel bovenaan in het glasraam verbeeldt Gods boodschap. De
                  wervelende berweging in het midden van het raam staat symbool voor
                  woelige tijden, waardoor het goudgele licht toch aanwezig blijft. De
                  rode vuurglinsters die uit het register springen staan symbool voor
                  mensen die geraakt worden door Gods boodschap.
                </p>
              </div>
            </div>
            <div className="GlassContainerItem">
              <div className="left">
                <br />
                <p className="muted">Glasraam 3</p>
                <br />
                <div className="item-image-container">
                  <img src={glasraam_3} alt="Glasraam"></img>
                </div>{" "}
              </div>
              <br />
              <div className="right">
                <div>
                  <h1>De abdij van Herkenrode</h1>
                </div>
                <br />
                <p className="item-content">
                  De abdij van Herkenrode en de regerende abdissen hebben doorheen de
                  eeuwen een belangrijke rol gespeeld in de ontwikkeling van de parochie.
                  Zij waren prominent aanwezig bij de bouw van de kerk. Onder het
                  abbatiaat van Augustina van Hamme (Abdis van Herkenrode, 1777 - 1790)
                  kreeg de Sint-Gertrudiskerk haar huidige vorm.
                  <br />
                  <br />
                  Net zoals een abdij een oord is van bezinning en gebed, wil de
                  parochiekerk van Kuringen dit ook zijn voor gewone gelovigen in drukke
                  tijden. Centraal op het raam staat een figuur die het kruis omarmt. Het
                  kruis staat voor de relatie met God, waarvoor gelovingen kiezen. De
                  parel staat voor de gelovigen mens, die zicht tot God ontmoet. Onderaan
    symboliseren de rode bloemen de verwelkoming van God voor iedere mens.
                  In zijn geheel staat dit glasraam sybmbool voor de gelovige gemeenschap
                  die ook het goddelijke licht doorlaat (door het glasraam schijnt het
                  licht).
                </p>
              </div>
            </div>
            <div className="GlassContainerItem">
              <div className="left">
                <br />
                <p className="muted">Glasraam 4</p>
                <br />
                <div className="item-image-container">
                  <img src={glasraam_4} alt="Glasraam"></img>
                </div>
              </div>
              <br />
              <div className="right">
                <div>
                  <h1>De ontmoeting van de levende Heer</h1>
                </div>
                <br />
                <p className="item-content">
                  Dit glasraam verbeeldt de ontmoeting van de Sint-Gertrudisparochie met
                  God. Het grondplan van het romaanse kerkje in het bovenste gedeelte van
                  het raam verwijst naar het onstaan van de parochie. Centraal in het
                  grondplan verbeeldt een cirkel de hostie, symbool van de
                  eucharistieviering. Tegelijkertijd is de cirkelvorm ook een verbeelding
                  van de gelovige gemeenschap die samenkomt tijdens de eucharistie. De
                  rode kernen zijn de gelovigen die in een grote berweging het geloof
                  uitdragen. De goudgele goddelijke aanwezigheid straalt vanuit deze
                  beweging. De zwarte wolken in ieders leven kunnen getrotseerd worden
                  dankzij het geloof.
                </p>
              </div>
            </div>
            <div className="item-navigation-control">
              <a href={previousItemLink}>Vorige</a>
              23
              <a href={nextItemLink}>Volgende</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Glasramen;
