import "../assets/style/main.scss";
import "../assets/style/info.scss";

import ReactAudioPlayer from 'react-audio-player';

import d1 from '../assets/audio/d1.wav';
import d2 from '../assets/audio/d2.wav';
import d3 from '../assets/audio/d3.wav';
import d4 from '../assets/audio/d4.wav';
import d5 from '../assets/audio/d5.wav';
import d6 from '../assets/audio/d6.wav';
import d7 from '../assets/audio/d7.wav';
import d8 from '../assets/audio/d8.wav';
import d9 from '../assets/audio/d9.wav';


import React from "react";

import Banner from "../components/Banner.js";

function Info() {
  return (
    <div>
      <Banner />
      <a href="/" className="breadcrumb">
        Ga terug naar startscherm{" "}
      </a>
      <div className="infoContainer">
        <div>
          <h1>Noveen</h1>
          <br />
          <p>
          Gertrudis van Nijvel werd in 626 geboren als dochter van Pepijn van Landen en de Heilige Ida van Nijvel. Al van jongs af wilde ze enkel Christus dienen en werd zuster in de door haar moeder gestichte de abdij van Nijvel. Hiervan werd ze reeds op 21-jarige leeftijd abdis (652). Ze was gekend voor haar kennis van Gods woord, naastenliefde, menslievendheid en gastvrijheid.  Gertrudis overleed rond haar 33ste levensjaar op 17 maart 659, en ligt begraven in het klooster te Nijvel. Haar stoffelijke resten rusten sinds 1272 in een kostbare reliekschrijn in een aan haar gewijde kerk. 
          </p><br />
          <p>
          De Heilige Gertrudis wordt op meer dan 1 000 plaatsen, in de hele wereld aanbeden. Aangezien haar familie  verschillende eigendommen te Kuringen bezat, is het zeer goed mogelijk dat Gertrudis hier een deel van haar jeugd heeft doorgebracht. Waardoor ze ook in Kuringen bijzonder wordt vereerd. 
          </p><br />
          <p>
          De Heilige Gertrudis is de beschermster der zieken en de patrones van de reizigers. In Kuringen is ze echter vooral bekend als behoedster tegen ratten- en muizenplagen.
          </p><br />
          <p>
          Ter ere van haar feestdag op 17 maart werd er sinds jaar en dag vanaf 9 maart een Noveen gebeden ter ere van de Heilige Gertrudis. Graag willen we deze traditie via digitale weg in ere houden. Hieronder kunnen jullie de link naar de geluidsfragmenten van de verschillende negen dagen terugvinden. 
          </p><br />
        </div>
        <h1>Audio fragmenten</h1>
        <br/>
        <div className="noveen-dag">
          <p>Dag 1</p>
          <h2>9 Maart</h2>
          <div className="audio-player">
            <ReactAudioPlayer
              src={d1}
              controls
            />
          </div>
        </div>
        <div className="noveen-dag">
          <p>Dag 2</p>
          <h2>10 Maart</h2>
          <div className="audio-player">
            <ReactAudioPlayer
              src={d2}
              controls
            />
          </div>
        </div>
        <div className="noveen-dag">
          <p>Dag 3</p>
          <h2>11 Maart</h2>
          <div className="audio-player">
            <ReactAudioPlayer
              src={d3}
              controls
            />
          </div>
        </div>
        <div className="noveen-dag">
          <p>Dag 4</p>
          <h2>12 Maart</h2>
          <div className="audio-player">
            <ReactAudioPlayer
              src={d4}
              controls
            />
          </div>
        </div>
        <div className="noveen-dag">
        <p>Dag 5</p>
          <h2>13 Maart</h2>
          <div className="audio-player">
            <ReactAudioPlayer
              src={d5}
              controls
            />
          </div>
        </div>
        <div className="noveen-dag">
          <p>Dag 6</p>
          <h2>14 Maart</h2>
          <div className="audio-player">
            <ReactAudioPlayer
              src={d6}
              controls
            />
          </div>
        </div>
        <div className="noveen-dag">
          <p>Dag 7</p>
          <h2>15 Maart</h2>
          <div className="audio-player">
            <ReactAudioPlayer
              src={d7}
              controls
            />
          </div>
        </div>
        <div className="noveen-dag">
          <p>Dag 8</p>
          <h2>16 Maart</h2>
          <div className="audio-player">
            <ReactAudioPlayer
              src={d8}
              controls
            />
          </div>
        </div>
        <div className="noveen-dag">
        <p>Dag 9</p>
          <h2>17 Maart</h2>
          <div className="audio-player">
            <ReactAudioPlayer
              src={d9}
              controls
            />
          </div>
        </div>
        <br /><br />
        {/* <div>
          <h1> Info </h1>
          <p> De kerk is altijd open van 9u tot 17u</p>
          <p> Misvieringen gaan door op Zondag 11u </p>
        </div>
        <br />
        <div>
          <h1> Contact </h1>
          <div className="contactContainer">
            <div>
              <h3> Kerkraad </h3>
              <ul>
                <li>
                  Jan Vanderlinden - <a href="tel:011253668">011 25 36 68</a>
                </li>
                <li>Kerkfabriek.stgertrudis@gmail.com</li>
              </ul>
            </div>
            <div>
              <h3> Koster </h3>
              <ul>
                <li>
                  Valère Vanmechelen - <a href="tel:011871264">011 87 12 64</a>
                </li>
              </ul>
            </div>

            <div>
              <h3> Misdienaarswerking </h3>
              <ul>
                <li>
                  Pastoor <br /> E.H. Gerard Janssen -{" "}
                  <a href="tel:0478292299">0478 29 22 99</a>
                </li>
              </ul>
            </div>

            <div>
              <h3> St. Ceciliakoor </h3>
              <ul>
                <li>
                  Magda Willems - <a href="tel:0477263270">0477 26 32 70</a>
                </li>
              </ul>
            </div>

            <div>
              <h3> Bloemengroep </h3>
              <ul>
                <li>
                  Paula Knaepen-Asnong - <a href="tel:011274949">011 27 49 49</a>
                </li>
              </ul>
            </div>

            <div>
              <h3> Misintenties </h3>
              <ul>
                <li>
                  Valère Vanmechelen - <a href="tel:011871264">011 87 12 64</a>
                </li>
                <li>
                  Hilda Deferme - <a href="tel:0478265003">0478 26 50 03</a>
                </li>
              </ul>
            </div>

            <div>
              <h3> Secretariaat </h3>
              <ul>
                <li>Joris van Oostenrijkstraat 61</li>
                <li>
                  <em className="muted">
                    Elke woensdag van 09u tot 12u - niet onder de schoolvakantie
                  </em>
                </li>
              </ul>
            </div>

            <div>
              <h3> Parochiecentrum Gildezaal </h3>
              <ul>
                <li>
                  Lea Willems - <a href="tel:011421051">011 42 10 51</a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Info;
