import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home.js";
import Rondleiding from "./pages/Rondleiding.js";
import Info from "./pages/Info.js";

import {
  Item1,
  Item2,
  Item3,
  Item4,
  Item5,
  Item6,
  Item7,
  Item8,
  Item9,
  Item10,
  Item11,
  Item12,
  Item13,
  Item14,
  Item15,
  Item16,
  Item17,
  Item18,
  Item19,
  Item20,
  Item21,
  Item22,
  // Item23,
  Item24,
} from "./components/rondleiding/Items";

import Glasramen from "./pages/Glasramen.js";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/noveen" element={<Info />} />
          <Route path="/rondleiding" element={<Rondleiding />} />
          <Route path="/rondleiding/1" element={<Item1 />} />
          <Route path="/rondleiding/2" element={<Item2 />} />
          <Route path="/rondleiding/3" element={<Item3 />} />
          <Route path="/rondleiding/4" element={<Item4 />} />
          <Route path="/rondleiding/5" element={<Item5 />} />
          <Route path="/rondleiding/6" element={<Item6 />} />
          <Route path="/rondleiding/7" element={<Item7 />} />
          <Route path="/rondleiding/8" element={<Item8 />} />
          <Route path="/rondleiding/9" element={<Item9 />} />
          <Route path="/rondleiding/10" element={<Item10 />} />
          <Route path="/rondleiding/11" element={<Item11 />} />
          <Route path="/rondleiding/12" element={<Item12 />} />
          <Route path="/rondleiding/13" element={<Item13 />} />
          <Route path="/rondleiding/14" element={<Item14 />} />
          <Route path="/rondleiding/15" element={<Item15 />} />
          <Route path="/rondleiding/16" element={<Item16 />} />
          <Route path="/rondleiding/17" element={<Item17 />} />
          <Route path="/rondleiding/18" element={<Item18 />} />
          <Route path="/rondleiding/19" element={<Item19 />} />
          <Route path="/rondleiding/20" element={<Item20 />} />
          <Route path="/rondleiding/21" element={<Item21 />} />
          <Route path="/rondleiding/22" element={<Item22 />} />
          <Route path="/rondleiding/23" element={<Glasramen />} />
          <Route path="/rondleiding/24" element={<Item24 />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
