import React from "react";

class Banner extends React.Component {
    render() {
        return <div className="banner">
                <h1>Sint-Gertrudiskerk</h1>
        </div>
    }
}

export default Banner;
