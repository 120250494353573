import "../../assets/style/main.scss";
import "../../assets/style/rondleiding.scss";

import Banner from "../Banner.js";

function Template(props) {
  const currentItem = props.item.id;
  const nextItemLink =
    "/rondleiding/" + (parseInt(currentItem) === 24 ? 1 : currentItem + 1);
  const previousItemLink =
    "/rondleiding/" + (parseInt(currentItem) === 1 ? 24 : currentItem - 1);

  return (
    <div className="template-background">
      <Banner />
      <a href="/rondleiding" className="breadcrumb">
        Ga terug naar de lijst
      </a>
      <div className="item-container" id={props.item.id}>
        <div className="item-container-image">
          <div className="item-content-container">
            <div className="item-image-container">
              <img src={props.img} alt={props.item.name}></img>
            </div>
            <div className="item-navigation-control pc-ninja">
              <a href={previousItemLink}>Vorige</a>
              {props.item.id}
              <a href={nextItemLink}>Volgende</a>
            </div>
          </div>
        </div>
        <div className="item-container-content">
        <div>
            <h1>Nummer {props.item.id}: {props.item.name}</h1>
          </div>
          {/* <p className="muted">
            Nummer  {props.item.name}
          </p> */}
          <br />
          <p className="item-content">{props.item.content}</p>

          <div className="item-navigation-control ">
            <a href={previousItemLink}>Vorige</a>
            {props.item.id}
            <a href={nextItemLink}>Volgende</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Template;
